:root {
  --bg-gradient-onyx: #3f3f40;
  --bg-gradient-jet: #202022;
  --bg-gradient-yellow-1: linear-gradient(to bottom right, #ffda6b 0%, #ffc06100 50%);
  --bg-gradient-yellow-2: linear-gradient(135deg, #ffda6b40 0%, #ffbb5c00 59.86%), #202022;
  --border-gradient-onyx: #404040;
  --text-gradient-yellow: #ffdb70;
  --jet: #383838;
  --onyx: #2b2b2c;
  --eerie-black-1: #202022;
  --eerie-black-2: #1e1e1f;
  --smoky-black: #121212;
  --white-1: #fff;
  --white-2: #fafafa;
  --orange-yellow-crayola: #ffdb70;
  --vegas-gold: #ceb15a;
  --light-gray: #d6d6d6;
  --light-gray-70: #d6d6d6b3;
  --light-gray-50: #d6d6d680;
  --bittersweet-shimmer: #b84c4c;
  --ff-poppins: "Poppins", sans-serif;
  --fs-1: 24px;
  --fs-2: 18px;
  --fs-3: 17px;
  --fs-4: 16px;
  --fs-5: 15px;
  --fs-6: 14px;
  --fs-7: 13px;
  --fs-8: 11px;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --shadow-1: -4px 8px 24px #00000040;
  --shadow-2: 0 16px 30px #00000040;
  --shadow-3: 0 16px 40px #00000040;
  --shadow-4: 0 25px 50px #00000026;
  --shadow-5: 0 24px 80px #00000040;
  --transition-1: .25s ease;
  --transition-2: .5s ease-in-out;
}

@font-face {
  font-family: Futura;
  src: url("FuturaNowHeadlineBold.4282a914.ttf");
}

*, :before, :after {
  box-sizing: border-box;
  transition: background-color var(--transition-2), color var(--transition-2), border-color var(--transition-2);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

img, ion-icon, a, button, time, span {
  display: block;
}

button {
  font: inherit;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
}

input, textarea {
  font: inherit;
  background: none;
  width: 100%;
  display: block;
}

::selection {
  background: var(--orange-yellow-crayola);
  color: var(--smoky-black);
}

:focus {
  outline-color: var(--orange-yellow-crayola);
}

html {
  font-family: var(--ff-poppins);
}

body {
  background: var(--smoky-black);
}

.scroll-snap {
  transition: var(--transition-2);
}

.sidebar, article {
  background: var(--eerie-black-2);
  border: 1px solid var(--jet);
  box-shadow: var(--shadow-1);
  z-index: 1;
  border-radius: 20px;
  padding: 15px;
}

.separator {
  background: var(--jet);
  width: 100%;
  height: 1px;
  margin: 16px 0;
}

.icon-box {
  background: var(--border-gradient-onyx);
  color: var(--orange-yellow-crayola);
  box-shadow: var(--shadow-1);
  z-index: 1;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  display: flex;
  position: relative;
}

.icon-box:before {
  content: "";
  background: var(--eerie-black-1);
  border-radius: inherit;
  z-index: -1;
  position: absolute;
  inset: 1px;
}

.icon-box ion-icon {
  --ionicon-stroke-width: 35px;
}

article {
  display: none;
}

article.active {
  animation: .5s backwards fade;
  display: block;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.h2, .h3, .h4, .h5 {
  color: var(--white-2);
  text-transform: capitalize;
}

.h2 {
  font-size: var(--fs-1);
}

.h3 {
  font-size: var(--fs-2);
}

.h4 {
  font-size: var(--fs-4);
}

.h5 {
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
}

.article-title {
  padding-bottom: 7px;
  position: relative;
}

.article-title:after {
  content: "";
  background: var(--text-gradient-yellow);
  border-radius: 3px;
  width: 30px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.has-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.has-scrollbar::-webkit-scrollbar-track {
  background: var(--onyx);
  border-radius: 5px;
}

.has-scrollbar::-webkit-scrollbar-thumb {
  background: var(--orange-yellow-crayola);
  border-radius: 5px;
}

.has-scrollbar::-webkit-scrollbar-button {
  width: 20px;
}

.content-card {
  background: var(--border-gradient-onyx);
  box-shadow: var(--shadow-2);
  cursor: pointer;
  z-index: 1;
  border-radius: 14px;
  padding: 45px 15px 15px;
  position: relative;
}

.content-card:before {
  content: "";
  background: var(--bg-gradient-jet);
  border-radius: inherit;
  z-index: -1;
  position: absolute;
  inset: 1px;
}

main {
  min-width: 259px;
  margin: 15px 12px 75px;
}

.sidebar {
  max-height: 112px;
  margin-bottom: 15px;
  overflow: hidden;
}

.sidebar.active {
  max-height: 405px;
}

.sidebar-info {
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
}

.avatar-box {
  background: var(--bg-gradient-onyx);
  border-radius: 20px;
}

.info-content .name {
  color: var(--white-2);
  font-size: var(--fs-3);
  font-weight: var(--fw-500);
  letter-spacing: -.25px;
  margin-bottom: 10px;
}

.info-content .title {
  color: var(--white-1);
  background: var(--onyx);
  font-size: var(--fs-8);
  font-weight: var(--fw-300);
  border-radius: 8px;
  width: max-content;
  padding: 3px 12px;
}

.info_more-btn {
  color: var(--orange-yellow-crayola);
  background: var(--border-gradient-onyx);
  box-shadow: var(--shadow-2);
  z-index: 1;
  border-radius: 0 15px;
  padding: 10px;
  font-size: 13px;
  position: absolute;
  top: -15px;
  right: -15px;
}

.info_more-btn:before {
  content: "";
  border-radius: inherit;
  background: var(--bg-gradient-jet);
  z-index: -1;
  position: absolute;
  inset: 1px;
}

.info_more-btn:hover, .info_more-btn:focus {
  background: var(--bg-gradient-yellow-1);
}

.info_more-btn:hover:before, .info_more-btn:focus:before {
  background: var(--bg-gradient-yellow-2);
}

.info_more-btn span {
  display: none;
}

.sidebar-info_more {
  opacity: 0;
  visibility: hidden;
}

.sidebar.active .sidebar-info_more {
  opacity: 1;
  visibility: visible;
}

.contacts-list {
  grid-template-columns: 1fr;
  gap: 16px;
  display: grid;
}

.contact-item {
  align-items: center;
  gap: 16px;
  min-width: 100%;
  display: flex;
}

.contact-info {
  width: calc(100% - 46px);
  max-width: calc(100% - 46px);
}

.contact-title {
  color: var(--light-gray-70);
  font-size: var(--fs-8);
  text-transform: uppercase;
  margin-bottom: 2px;
}

.contact-info :is(.contact-link, time, address) {
  color: var(--white-2);
  font-size: var(--fs-7);
}

.contact-info address {
  font-style: normal;
}

.social-list {
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding-bottom: 4px;
  padding-left: 7px;
  display: flex;
}

.social-item .social-link {
  color: var(--light-gray-70);
  font-size: 18px;
}

.social-item .social-link:hover {
  color: var(--light-gray);
}

.navbar {
  backdrop-filter: blur(10px);
  border: 1px solid var(--jet);
  box-shadow: var(--shadow-2);
  z-index: 5;
  background: #2b2b2cbf;
  border-radius: 12px 12px 0 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.navbar-list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.navbar-link {
  color: var(--light-gray);
  font-size: var(--fs-8);
  padding: 20px 7px;
}

.navbar-link:hover, .navbar-link:focus {
  color: var(--light-gray-70);
}

.navbar-link.active {
  color: var(--orange-yellow-crayola);
}

.about .article-title {
  margin-bottom: 15px;
}

.about-text {
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  line-height: 1.6;
}

.about-text p {
  margin-bottom: 15px;
}

.service {
  margin-bottom: 35px;
}

.service-title {
  margin-bottom: 20px;
}

.service-list {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
}

.service-item {
  background: var(--border-gradient-onyx);
  box-shadow: var(--shadow-2);
  z-index: 1;
  border-radius: 14px;
  padding: 20px;
  position: relative;
}

.service-item:before {
  content: "";
  background: var(--bg-gradient-jet);
  border-radius: inherit;
  z-index: -1;
  position: absolute;
  inset: 1px;
}

.service-icon-box {
  margin-bottom: 10px;
}

.service-icon-box img {
  margin: auto;
}

.service-content-box {
  text-align: center;
}

.service-item-title {
  margin-bottom: 7px;
}

.service-item-text {
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  line-height: 1.6;
}

.testimonials {
  margin-bottom: 30px;
}

.testimonials-title {
  margin-bottom: 20px;
}

.testimonials-list {
  scroll-behavior: smooth;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin: 0 -15px;
  padding: 25px 15px 35px;
  display: flex;
  overflow-x: auto;
}

.testimonials-item {
  scroll-snap-align: center;
  transition: background-color var(--transition-2);
  min-width: 100%;
}

.testimonials-avatar-box {
  background: var(--bg-gradient-onyx);
  box-shadow: var(--shadow-1);
  border-radius: 14px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(15px, -25px);
}

.testimonials-item-title {
  margin-bottom: 7px;
}

.testimonials-text {
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.6;
  display: -webkit-box;
  overflow: hidden;
}

.modal-container {
  overscroll-behavior: contain;
  z-index: 20;
  pointer-events: none;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.modal-container::-webkit-scrollbar {
  display: none;
}

.modal-container.active {
  pointer-events: all;
  visibility: visible;
}

.overlay {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 1;
  background: #0d0d0d;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay.active {
  opacity: .8;
  visibility: visible;
  pointer-events: all;
}

.testimonials-modal {
  background: var(--eerie-black-2);
  border: 1px solid var(--jet);
  box-shadow: var(--shadow-5);
  opacity: 0;
  z-index: 2;
  border-radius: 14px;
  margin: 15px 12px;
  padding: 15px;
  position: relative;
  transform: scale(1.2);
}

.modal-container.active .testimonials-modal {
  opacity: 1;
  transform: scale(1);
}

.modal-close-btn {
  background: var(--onyx);
  color: var(--white-2);
  opacity: .7;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 18px;
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-close-btn:hover, .modal-close-btn:focus {
  opacity: 1;
}

.modal-close-btn ion-icon {
  --ionicon-stroke-width: 50px;
}

.modal-avatar-box {
  background: var(--bg-gradient-onyx);
  box-shadow: var(--shadow-2);
  border-radius: 14px;
  width: max-content;
  margin-bottom: 15px;
}

.modal-img-wrapper > img {
  display: none;
}

.modal-title {
  margin-bottom: 4px;
}

.modal-content time {
  font-size: var(--fs-6);
  color: var(--light-gray-70);
  font-weight: var(--fw-300);
  margin-bottom: 10px;
}

.modal-content p {
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  line-height: 1.6;
}

.clients {
  margin-bottom: 15px;
}

.clients-list {
  scroll-behavior: smooth;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin: 0 -15px;
  padding: 25px;
  scroll-padding-inline: 25px;
  display: flex;
  overflow-x: auto;
}

.clients-item {
  scroll-snap-align: start;
  min-width: 50%;
}

.clients-item img {
  width: 80%;
}

.article-title, .timeline {
  margin-bottom: 30px;
}

.timeline .title-wrapper {
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
  display: flex;
}

.timeline-list {
  font-size: var(--fs-6);
  margin-left: 45px;
}

.timeline-item {
  position: relative;
}

.timeline-item:not(:last-child) {
  margin-bottom: 20px;
}

.timeline-item-title {
  font-size: var(--fs-6);
  margin-bottom: 7px;
  line-height: 1.3;
}

.timeline-list span {
  color: var(--vegas-gold);
  font-weight: var(--fw-400);
  line-height: 1.6;
}

.timeline-item:not(:last-child):before {
  content: "";
  background: var(--jet);
  width: 1px;
  height: calc(100% + 50px);
  position: absolute;
  top: -25px;
  left: -30px;
}

.timeline-item:after {
  content: "";
  background: var(--text-gradient-yellow);
  box-shadow: 0 0 0 4px var(--jet);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 5px;
  left: -33px;
}

.timeline-text {
  color: var(--light-gray);
  font-weight: var(--fw-300);
  line-height: 1.6;
}

.skills-title {
  margin-bottom: 20px;
}

.skills-list {
  padding: 20px;
}

.skills-item:not(:last-child) {
  margin-bottom: 15px;
}

.skill .title-wrapper {
  align-items: center;
  gap: 5px;
  margin-bottom: 8px;
  display: flex;
}

.skill .title-wrapper data {
  color: var(--light-gray);
  font-size: var(--fs-7);
  font-weight: var(--fw-300);
}

.skill-progress-bg {
  background: var(--jet);
  border-radius: 10px;
  width: 100%;
  height: 8px;
}

.skill-progress-fill {
  background: var(--text-gradient-yellow);
  border-radius: inherit;
  height: 100%;
}

.filter-list {
  display: none;
}

.filter-select-box {
  margin-bottom: 25px;
  position: relative;
}

.filter-select {
  background: var(--eerie-black-2);
  color: var(--light-gray);
  border: 1px solid var(--jet);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  border-radius: 14px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  display: flex;
}

.filter-select.active .select-icon {
  transform: rotate(.5turn);
}

.select-list {
  background: var(--eerie-black-2);
  border: 1px solid var(--jet);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  border-radius: 14px;
  width: 100%;
  padding: 6px;
  transition: all .15s ease-in-out;
  position: absolute;
  top: calc(100% + 6px);
}

.filter-select.active + .select-list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.select-item button {
  background: var(--eerie-black-2);
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  text-transform: capitalize;
  border-radius: 8px;
  width: 100%;
  padding: 8px 10px;
}

.select-item button:hover {
  --eerie-black-2: #323234;
}

.project-list {
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 10px;
  display: grid;
}

.project-item {
  display: none;
}

.project-item.active {
  animation: .25s forwards scaleUp;
  display: block;
}

@keyframes scaleUp {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

.project-item > a {
  width: 100%;
}

.project-img {
  border-radius: 16px;
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

.project-img:before {
  content: "";
  z-index: 1;
  background: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.project-item > a:hover .project-img:before {
  background: #00000080;
}

.project-item-icon-box {
  --scale: .8;
  background: var(--jet);
  color: var(--orange-yellow-crayola);
  transform: translate(-50%, -50%) scale(var(--scale));
  opacity: 0;
  z-index: 1;
  border-radius: 12px;
  padding: 18px;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.project-item > a:hover .project-item-icon-box {
  --scale: 1;
  opacity: 1;
}

.project-item-icon-box ion-icon {
  --ionicon-stroke-width: 50px;
}

.project-img img {
  object-fit: cover;
  transition: var(--transition-2);
  width: 100%;
  height: 100%;
}

.project-item > a:hover img {
  transition: var(--transition-2);
  transform: scale(1.1);
}

.project-title, .project-category {
  margin-left: 10px;
}

.project-title {
  color: var(--white-2);
  font-size: var(--fs-5);
  font-weight: var(--fw-400);
  text-transform: capitalize;
  line-height: 1.3;
}

.project-category {
  color: var(--light-gray-70);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
}

.blog-posts {
  margin-bottom: 10px;
}

.blog-posts-list {
  grid-template-columns: 1fr;
  gap: 20px;
  display: grid;
}

.blog-post-item {
  animation: .25s forwards scaleUp;
}

.blog-post-item > a {
  background: var(--border-gradient-onyx);
  box-shadow: var(--shadow-4);
  z-index: 1;
  border-radius: 16px;
  height: 100%;
  position: relative;
}

.blog-post-item > a:before {
  content: "";
  border-radius: inherit;
  background: var(--eerie-black-1);
  z-index: -1;
  position: absolute;
  inset: 1px;
}

.blog-banner-box {
  border-radius: 12px;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.blog-banner-box img {
  object-fit: cover;
  transition: var(--transition-1);
  width: 100%;
  height: 100%;
}

.blog-post-item > a:hover .blog-banner-box img {
  transform: scale(1.1);
}

.blog-content {
  padding: 15px;
}

.blog-meta {
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  margin-bottom: 10px;
  display: flex;
}

.blog-meta :is(.blog-category, time) {
  color: var(--light-gray-70);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
}

.blog-meta .dot {
  background: var(--light-gray-70);
  border-radius: 4px;
  width: 4px;
  height: 4px;
}

.blog-item-title {
  margin-bottom: 10px;
  line-height: 1.3;
}

.blog-post-item > a:hover .blog-item-title {
  color: var(--orange-yellow-crayola);
}

.blog-text {
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  line-height: 1.6;
}

.mapbox {
  border: 1px solid var(--jet);
  border-radius: 16px;
  width: 100%;
  height: 250px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.mapbox figure {
  height: 100%;
}

.mapbox iframe {
  filter: grayscale() invert();
  border: none;
  width: 100%;
  height: 100%;
}

.contact-form {
  margin-bottom: 10px;
}

.form-title {
  margin-bottom: 20px;
}

.input-wrapper {
  grid-template-columns: 1fr;
  gap: 25px;
  margin-bottom: 25px;
  display: grid;
}

.form-input {
  color: var(--white-2);
  font-size: var(--fs-6);
  font-weight: var(--fw-400);
  border: 1px solid var(--jet);
  border-radius: 14px;
  outline: none;
  padding: 13px 20px;
}

.form-input::placeholder {
  font-weight: var(--fw-500);
}

.form-input:focus {
  border-color: var(--orange-yellow-crayola);
}

textarea.form-input {
  resize: vertical;
  height: 120px;
  min-height: 100px;
  max-height: 200px;
  margin-bottom: 25px;
}

textarea.form-input::-webkit-resizer {
  display: none;
}

.form-input:focus:invalid {
  border-color: var(--bittersweet-shimmer);
}

.form-btn {
  background: var(--border-gradient-onyx);
  color: var(--orange-yellow-crayola);
  font-size: var(--fs-6);
  text-transform: capitalize;
  box-shadow: var(--shadow-3);
  z-index: 1;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 13px 20px;
  display: flex;
  position: relative;
}

.form-btn:before {
  content: "";
  background: var(--bg-gradient-jet);
  border-radius: inherit;
  z-index: -1;
  position: absolute;
  inset: 1px;
}

.form-btn ion-icon {
  font-size: 16px;
}

.form-btn:hover {
  background: var(--bg-gradient-yellow-1);
}

.form-btn:hover:before {
  background: var(--bg-gradient-yellow-2);
}

.form-btn:disabled {
  opacity: .7;
  cursor: not-allowed;
}

.form-btn:disabled:hover {
  background: var(--border-gradient-onyx);
}

.form-btn:disabled:hover:before {
  background: var(--bg-gradient-jet);
}

@media (width >= 450px) {
  .clients-item {
    min-width: calc(33.33% - 10px);
  }

  .project-img, .blog-banner-box {
    height: auto;
  }
}

@media (width >= 580px) {
  :root {
    --fs-1: 32px;
    --fs-2: 24px;
    --fs-3: 26px;
    --fs-4: 18px;
    --fs-6: 15px;
    --fs-7: 15px;
    --fs-8: 12px;
  }

  .sidebar, article {
    width: 520px;
    margin-inline: auto;
    padding: 30px;
  }

  .article-title {
    font-weight: var(--fw-600);
    padding-bottom: 15px;
  }

  .article-title:after {
    width: 40px;
    height: 5px;
  }

  .icon-box {
    border-radius: 12px;
    width: 48px;
    height: 48px;
    font-size: 18px;
  }

  main {
    margin-top: 60px;
    margin-bottom: 100px;
  }

  .sidebar {
    max-height: 180px;
    margin-bottom: 30px;
  }

  .sidebar.active {
    max-height: 584px;
  }

  .sidebar-info {
    gap: 25px;
  }

  .avatar-box {
    border-radius: 30px;
  }

  .avatar-box img {
    width: 120px;
  }

  .info-content .name {
    margin-bottom: 15px;
  }

  .info-content .title {
    padding: 5px 18px;
  }

  .info_more-btn {
    padding: 10px 15px;
    top: -30px;
    right: -30px;
  }

  .info_more-btn span {
    font-size: var(--fs-8);
    display: block;
  }

  .info_more-btn ion-icon {
    display: none;
  }

  .separator {
    margin: 32px 0;
  }

  .contacts-list {
    gap: 20px;
  }

  .contact-info {
    width: calc(100% - 64px);
    max-width: calc(100% - 64px);
  }

  .navbar {
    border-radius: 20px 20px 0 0;
  }

  .navbar-list {
    gap: 20px;
  }

  .navbar-link {
    --fs-8: 14px;
  }

  .about .article-title {
    margin-bottom: 20px;
  }

  .about-text {
    margin-bottom: 40px;
  }

  .service-item {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
    padding: 30px;
    display: flex;
  }

  .service-icon-box {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .service-content-box {
    text-align: left;
  }

  .testimonials-title {
    margin-bottom: 25px;
  }

  .testimonials-list {
    gap: 30px;
    margin: 0 -30px;
    padding: 30px 30px 35px;
  }

  .content-card {
    padding: 25px 30px 30px;
  }

  .testimonials-avatar-box {
    border-radius: 20px;
    transform: translate(30px, -30px);
  }

  .testimonials-avatar-box img {
    width: 80px;
  }

  .testimonials-item-title {
    margin-bottom: 10px;
    margin-left: 95px;
  }

  .testimonials-text {
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }

  .modal-container {
    padding: 20px;
  }

  .testimonials-modal {
    border-radius: 20px;
    justify-content: flex-start;
    align-items: stretch;
    gap: 25px;
    padding: 30px;
    display: flex;
  }

  .modal-img-wrapper {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .modal-avatar-box {
    border-radius: 18px;
    margin-bottom: 0;
  }

  .modal-avatar-box img {
    width: 65px;
  }

  .modal-img-wrapper > img {
    flex-grow: 1;
    width: 35px;
    display: block;
  }

  .clients-list {
    gap: 50px;
    margin: 0 -30px;
    padding: 45px;
    scroll-padding-inline: 45px;
  }

  .clients-item {
    min-width: calc(33.33% - 35px);
  }

  .timeline-list {
    margin-left: 65px;
  }

  .timeline-item:not(:last-child):before {
    left: -40px;
  }

  .timeline-item:after {
    width: 8px;
    height: 8px;
    left: -43px;
  }

  .skills-item:not(:last-child) {
    margin-bottom: 25px;
  }

  .project-img, .blog-banner-box {
    border-radius: 16px;
  }

  .blog-posts-list {
    gap: 30px;
  }

  .blog-content {
    padding: 25px;
  }

  .mapbox {
    border-radius: 18px;
    height: 380px;
  }

  .input-wrapper {
    gap: 30px;
    margin-bottom: 30px;
  }

  .form-input {
    padding: 15px 20px;
  }

  textarea.form-input {
    margin-bottom: 30px;
  }

  .form-btn {
    --fs-6: 16px;
    padding: 16px 20px;
  }

  .form-btn ion-icon {
    font-size: 18px;
  }
}

@media (width >= 768px) {
  .sidebar, article {
    width: 700px;
  }

  .has-scrollbar::-webkit-scrollbar-button {
    width: 100px;
  }

  .contacts-list {
    grid-template-columns: 1fr 1fr;
    gap: 30px 15px;
  }

  .navbar-link {
    --fs-8: 15px;
  }

  .testimonials-modal {
    gap: 35px;
    max-width: 680px;
  }

  .modal-avatar-box img {
    width: 80px;
  }

  .article-title {
    padding-bottom: 20px;
  }

  .filter-select-box {
    display: none;
  }

  .filter-list {
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    margin-bottom: 30px;
    padding-left: 5px;
    display: flex;
  }

  .filter-item button {
    color: var(--light-gray);
    font-size: var(--fs-5);
  }

  .filter-item button:hover {
    color: var(--light-gray-70);
  }

  .filter-item button.active {
    color: var(--orange-yellow-crayola);
  }

  .project-list, .blog-posts-list, .input-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .form-btn {
    width: max-content;
    margin-left: auto;
  }
}

@media (width >= 1024px) {
  :root {
    --shadow-1: -4px 8px 24px #00000020;
    --shadow-2: 0 16px 30px #00000020;
    --shadow-3: 0 16px 40px #00000020;
  }

  .sidebar, article {
    box-shadow: var(--shadow-5);
    width: 950px;
  }

  main {
    margin-bottom: 60px;
  }

  .main-content {
    width: max-content;
    margin: auto;
    position: relative;
  }

  .navbar {
    box-shadow: none;
    border-radius: 0 20px;
    width: max-content;
    padding: 0 20px;
    position: absolute;
    inset: 0 0 auto auto;
  }

  .navbar-list {
    gap: 30px;
    padding: 0 20px;
  }

  .navbar-link {
    font-weight: var(--fw-500);
  }

  .service-list {
    grid-template-columns: 1fr 1fr;
    gap: 20px 25px;
  }

  .testimonials-item {
    min-width: calc(50% - 15px);
  }

  .clients-item {
    min-width: calc(25% - 38px);
  }

  .project-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .blog-banner-box {
    height: 230px;
  }
}

@media (width >= 1250px) {
  body::-webkit-scrollbar {
    width: 20px;
  }

  body::-webkit-scrollbar-track {
    background: var(--smoky-black);
  }

  body::-webkit-scrollbar-thumb {
    border: 5px solid var(--smoky-black);
    background: #ffffff1a;
    border-radius: 20px;
    box-shadow: inset 1px 1px #ffffff1c, inset -1px -1px #ffffff1c;
  }

  body::-webkit-scrollbar-thumb:hover {
    background: #ffffff26;
  }

  body::-webkit-scrollbar-button {
    height: 60px;
  }

  .sidebar, article {
    width: auto;
  }

  article {
    min-height: 100%;
  }

  main {
    justify-content: center;
    align-items: stretch;
    gap: 25px;
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
  }

  .main-content {
    width: 75%;
    min-width: 75%;
    margin: 0;
  }

  .sidebar {
    z-index: 1;
    height: 100%;
    max-height: max-content;
    margin-bottom: 0;
    padding-top: 60px;
    position: sticky;
    top: 60px;
  }

  .sidebar-info {
    flex-direction: column;
  }

  .avatar-box img {
    width: 150px;
  }

  .info-content .name {
    white-space: nowrap;
    text-align: center;
  }

  .info-content .title {
    margin: auto;
  }

  .info_more-btn {
    display: none;
  }

  .sidebar-info_more {
    opacity: 1;
    visibility: visible;
  }

  .contacts-list {
    grid-template-columns: 1fr;
  }

  .contact-info .contact-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .contact-info :is(.contact-link, time, address) {
    --fs-7: 14px;
    font-weight: var(--fw-300);
  }

  .separator:last-of-type {
    opacity: 0;
    margin: 15px 0;
  }

  .social-list {
    justify-content: center;
  }

  .timeline-text {
    max-width: 700px;
  }
}
/*# sourceMappingURL=index.f9b80bb5.css.map */
